<template>
  <div v-loading.fullscreen.lock="true"></div>
</template>

<script>
import OktaClient from "@/utils/OktaClient";
import _ from "lodash";
import { verifySocialProviderAuth } from "@/api/auth";

export default {
  name: "OktaLaunch",
  mounted() {
    let oktaClient;
    try {
      oktaClient = OktaClient.restoreFromStorage();
    } catch (e) {
      return this.redirectToLogin();
    }

    oktaClient
      .clearConfig()
      .getToken()
      .then(this.processTokens)
      .catch(this.redirectToLogin);
  },
  methods: {
    redirectToLogin() {
      this.$router.push({ path: "/login" });
      this.$message({
        type: "error",
        // eslint-disable-next-line
        message: __("User unauthorized")
      });
    },

    processTokens(tokens) {
      const accessToken = _.get(tokens, "accessToken");
      const refreshToken = _.get(tokens, "refreshToken");

      if (_.isNil(accessToken) || _.isNil(refreshToken)) {
        return this.redirectToLogin();
      }

      verifySocialProviderAuth("idpCrossLaunch", "", {
        type: "IDP",

        access_token: _.get(accessToken, "accessToken"),
        access_token_expire_at: `${_.get(accessToken, "expiresAt")}`,

        refresh_token: _.get(refreshToken, "refreshToken"),
        refresh_token_expire_at: `${_.get(accessToken, "expiresAt")}`
      }).catch(this.redirectToLogin);
    }
  }
};
</script>
